/**
 * Created by Dev team on 25/06/2024.
 */

(function btReportsCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btReportsCtrl', btReportsCtrl);

  btReportsCtrl.$inject = [
    '$scope',
    'btLinkDataService',
    '$ionicLoading',
    'btToastrService',
    'btShareScopeService',
    'btDateService',
  ];

  /**
   * Controller for about us page.
   *
   * @ngdoc controller
   * @name ecapp.btReportCtrl
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.ILinkDataService} btLinkDataService - ?
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IDateService} btDateService
   */
  function btReportsCtrl(
    $scope,
    btLinkDataService,
    $ionicLoading,
    btToastrService,
    btShareScopeService,
    btDateService
  ) {
    console.log('Running btReportsCtrlClosure');
    const today = moment().format('MM/DD/YYYY');
    $scope.loading = false;
    $scope.reportDate = {
      date: today,
      maxDate: today,
    };

    $scope.isToShowNote = false;
    $scope.hasEodReports = false;
    $scope.reports = null;
    $scope.deliveryMethodOptions = [
      {
        id: 'portal-download',
        label: 'Portal Download',
        selected: true,
        disabled: true,
      },
      {
        id: 'email',
        label: 'Email',
        selected: false,
        disabled: false,
      },
      {
        id: 'api-delivery',
        label: 'API Delivery (Enterprise Subscription Required)',
        selected: false,
        disabled: false,
      },
    ];
    $scope.viewFile = viewFile;
    $scope.downloadFile = downloadFile;
    $scope.contactUs = contactUs;
    $scope.onDateChanged = onDateChanged;

    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * Activate controller
     */
    function activate() {
      btShareScopeService
        .wait()
        .then(function () {
          $scope.hasEodReports = btShareScopeService.getAccountInfoField('eodReports');
          const timezone = btDateService.getUserTimeZone();
          $scope.isToShowNote = moment().tz(timezone).isBefore(moment.tz('2024-09-01', timezone));
        })
        .then(function () {
          if ($scope.hasEodReports) {
            getReports();
          }
        });
    }

    /**
     * Get reports
     */
    function getReports() {
      $scope.loading = true;
      $scope.reports = [];
      btLinkDataService
        .getReports(moment($scope.reportDate.date).format('YYYY-MM-DD'), btLinkDataService.reportTypes.EOD)
        .then(function (response) {
          if (response.reports && typeof response.reports === 'object' && Object.keys(response.reports).length > 0) {
            $scope.reports = Object.keys(response.reports).map(function (type) {
              const files = response.reports[type]
                .map(function (file) {
                  return {
                    name: file,
                    isPdf: file.toLowerCase().endsWith('.pdf'),
                  };
                })
                .sort(function (a, b) {
                  return (a.isPdf ? -1 : 1) - (b.isPdf ? -1 : 1);
                });

              return { type, files };
            });
          }
          $scope.loading = false;
        })
        .catch(function () {
          $scope.loading = false;
        });
    }

    /**
     * View file
     * @param {string} type - report type
     * @param {string} name - report name
     */
    function viewFile(type, name) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Fetching report link...</p>' });
      btLinkDataService
        .getSignedUrlOfReport(
          moment($scope.reportDate.date).format('YYYY-MM-DD'),
          type,
          name,
          btLinkDataService.reportTypes.EOD
        )
        .then(function (response) {
          if (response && response.url) {
            window.open(response.url, '_blank');
          }
          $ionicLoading.hide();
        })
        .catch(function () {
          $ionicLoading.hide();
          btToastrService.error('Failed to open file', 'Reports');
        });
    }

    /**
     * Download file
     * @param {string} type - report type
     * @param {string} name - report name
     */
    function downloadFile(type, name) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Fetching report file...</p>' });
      btLinkDataService
        .getFileContentOfReport(
          moment($scope.reportDate.date).format('YYYY-MM-DD'),
          type,
          name,
          btLinkDataService.reportTypes.EOD
        )
        .then(function (response) {
          if (response && response.data) {
            const downloadLink = document.createElement('a');
            downloadLink.href = response.data;
            downloadLink.download = name;
            downloadLink.click();
          }
          $ionicLoading.hide();
        })
        .catch(function () {
          $ionicLoading.hide();
          btToastrService.error('Failed to download file', 'Reports');
        });
    }

    /**
     * React on contact us
     * @param {Event} $event - event
     */
    function contactUs($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Submitting request access...</p>' });
      btLinkDataService
        .requestAccessForEodReport(
          $scope.deliveryMethodOptions.filter((d) => d.selected).map((d) => d.label),
          btLinkDataService.reportTypes.EOD
        )
        .then(function () {
          $ionicLoading.hide();
          btToastrService.success('Request access of EOW reports submitted successfully', 'Reports');
        })
        .catch(function () {
          $ionicLoading.hide();
          btToastrService.error('Failed to submit request access of EOW reports', 'Reports');
        });
    }

    /**
     * React on date changed
     */
    function onDateChanged() {
      getReports();
    }

    /**
     * React on scope destroy
     */
    function onDestroy() {
      console.log('Destroy btReportsCtrl');
    }
  }
})();
