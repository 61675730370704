/**
 * Created by yatree on 30/08/16.
 */

(function btTabsCtrlClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTabsCtrl:';

  angular.module('dashboard').controller('btTabsCtrl', btTabsCtrl);

  btTabsCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$location',
    '$analytics',
    'btShareScopeService',
    'btTradingService',
    'btInstrumentsService',
    'btSharedData',
    'btSettingsService',
    'btRestrictionService',
  ];

  /**
   * Now this controller handle account information for dashboard: user e-mail.
   *
   * @ngdoc controller
   * @name btTabsCtrl
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.ILocationService} $location
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btTabsCtrl(
    $scope,
    $rootScope,
    $timeout,
    $location,
    $analytics,
    btShareScopeService,
    btTradingService,
    btInstrumentsService,
    btSharedData,
    btSettingsService,
    btRestrictionService
  ) {
    console.log('Running btTabsCtrl');

    var gSettings = btSettingsService.getLocalSettings();
    var gDefaultTab = btSettingsService.isLinkDataService() ? 'dashboard-reports' : 'dashboard-charts';

    if ($rootScope.currentUser !== undefined && $rootScope.currentUser !== null) {
      $scope.userMail = $rootScope.currentUser.email;
    }

    $scope.wasLoaded = false;
    $scope.avatarObject = btShareScopeService.getAccountSettings();
    $scope.balance = { shown: false };

    $scope.isBigBrainBank = btSettingsService.isBigBrainBank();

    $scope.hasUpgrade = false;
    $scope.hasNextEvent = true;
    $scope.hasTradeBalance =
      btSettingsService.hasFeature('trading') && btRestrictionService.hasFeature('personalization');
    $scope.hasHelpCenter =
      btRestrictionService.hasFeature('personalization') &&
      !$scope.isBigBrainBank &&
      !btSettingsService.isLinkDataService();
    $scope.hasNotificationCenter = btRestrictionService.hasFeature('personalization');
    $scope.hasInitials = true;
    $scope.hasAccountMenu = btRestrictionService.hasFeature('personalization');
    $scope.isBrokerConnected = false;

    $scope.onTabSelected = onTabSelected;
    $scope.onTabDeselected = onTabDeselected;
    $scope.isTabHidden = isTabHidden;
    $scope.openOptimusForum = openOptimusForum;
    $scope.selectTab = selectTab;
    $scope.switchTvPopup = switchTvPopup;
    $scope.closeTvPopup = closeTvPopup;
    $scope.showVideo = gSettings.get('live-news.opened');
    $scope.openUpgrade = openUpgrade;
    $scope.closeLdsTrades = closeLdsTrades;
    $scope.showLdsTrades = false;
    $scope.ldsSelectedGrade = null;

    // EMDA
    $scope.emdaRedirection = emdaRedirection;

    // Sphere
    $scope.sphereRedirection = sphereRedirection;

    //$scope.minimized = false;

    $scope.$on('broker:connected', onBrokerConnected);
    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);
    $scope.$on('lds:trades:open', onLdsTradesOpen);
    $scope.$on('lds:trades:close', onLdsTradesClose);

    /**
     * Open LDS trades popup
     * @param {any} event
     * @param {number} grade
     */
    function onLdsTradesOpen(event, grade) {
      $scope.showLdsTrades = true;
      $scope.ldsSelectedGrade = grade;
    }

    /**
     * Close LDS trades popup
     */
    function onLdsTradesClose() {
      $scope.showLdsTrades = false;
    }

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      $scope.hasTradeBalance =
        btSettingsService.hasFeature('trading') && btRestrictionService.hasFeature('personalization');
      $scope.hasHelpCenter = btRestrictionService.hasFeature('personalization');
      $scope.hasNotificationCenter = btRestrictionService.hasFeature('personalization');
      $scope.hasAccountMenu = btRestrictionService.hasFeature('personalization');
      $scope.hasUpgrade = btSettingsService.isBetterTrader() && !btRestrictionService.hasFeature('backtesting');
    }

    activate();

    /**
     * This function activates desktop tabs controller.
     */
    function activate() {
      btShareScopeService
        .wait()
        .then(btTradingService.initialize)
        .then(btTradingService.connect)
        .then(btInstrumentsService.init)
        .then(function () {
          $scope.hasUpgrade = btSettingsService.isBetterTrader() && !btRestrictionService.hasFeature('backtesting');
          $scope.isBrokerConnected = btTradingService.isConnected() && !btTradingService.isDefaultBroker();
        });
    }

    /**
     * This function reacts on view loaded.
     *
     * @param {Object} event - ionic event
     * @param {*} data - event data
     */
    function onViewLoaded(event, data) {
      $scope.wasLoaded = true;
      if (gDebug) console.log(gPrefix, 'view loaded event', event);
      if (gDebug) console.log(gPrefix, 'view loaded data', data);
      if (gDebug) console.log(gPrefix, 'is loaded first time', event.targetScope === $scope);

      if (event.targetScope === $scope) openInitialTab();
    }

    /**
     * This function opens initial tab. It could be las opened tab or tab passed via url parameter `desktop`.
     */
    function openInitialTab() {
      // remove old settings
      if (window.localStorage.getItem('btDashboardTab')) {
        gSettings.set('dashboard.tab', window.localStorage.getItem('btDashboardTab'));
        window.localStorage.removeItem('btDashboardTab');
      }

      var viewName;

      // try to get desktop tab from url otherwise use local settings
      if (window.location.href.indexOf('desktop=') !== -1) {
        var params = $location.search();
        if (params.desktop) {
          viewName = params.desktop;
          delete params.desktop;
          $location.search(params);
          if (gDebug) console.log(gPrefix, 'desktop tab is ' + viewName + ' (url)');
        } else {
          viewName = gDefaultTab;
          if (gDebug) console.log(gPrefix, 'desktop tab can not be parsed from url');
        }
      } else {
        viewName = gSettings.get('dashboard.tab');
        if (gDebug) console.log(gPrefix, 'last desktop tab is ' + viewName);
      }

      // verify tab
      if (viewName && !isTabHidden(viewName)) {
        // if (viewName === 'dashboard-community' && !btRestrictionService.hasFeature('community')) {
        //   viewName = gDefaultTab;
        // }
        if (viewName === 'dashboard-twitter' && !btRestrictionService.hasFeature('twitter-scanner')) {
          viewName = gDefaultTab;
        }
      } else {
        viewName = gDefaultTab;
      }

      btSharedData
        .openDashboardTabByViewName(viewName)
        .then(function () {
          if (gDebug) console.log(gPrefix, 'last tab was opened');
        })
        .catch(function (reason) {
          console.error(reason);
        });
    }

    /**
     * This function reacts on broker connected.
     *
     * @param {Object} event - ionic event
     * @param {*} data - event data
     */
    function onBrokerConnected(event, data) {
      void event;
      void data;
      $scope.isBrokerConnected = btTradingService.isConnected() && !btTradingService.isDefaultBroker();
    }

    /**
     * This function checks tab visibility
     *
     * @param {string} name - name of view associated with tab
     * @return {boolean}
     */
    function isTabHidden(name) {
      switch (name) {
        case 'dashboard-info-hub':
          return !btRestrictionService.hasFeature('personalization') || !btSettingsService.hasSideTab(name);
        case 'dashboard-forum':
          return btRestrictionService.hasFeature('personalization') || !btSettingsService.hasSideTab(name);
        case 'dashboard-content-wall':
          return !(btShareScopeService.checkHasRole('developer') && btSettingsService.hasSideTab(name));
        case 'dashboard-trading-platform':
          return $rootScope.isTradeStation || !btSettingsService.hasSideTab(name);
        default:
          return !btSettingsService.hasSideTab(name);
      }
    }

    /**
     * This function open Optimus Futures community link
     */
    function openOptimusForum() {
      window.open('https://community.optimusfutures.com/c/futures-trading-platforms/optimus-news', '_system');
    }

    /**
     * This function reacts on tab selected.
     *
     * @param {String} name - tab name
     * @param {String} viewName - view name
     */
    function onTabSelected(name, viewName) {
      if (gDebug) console.log(gPrefix, 'onDashboardTabSelected', name, viewName);

      if ($scope.wasLoaded && viewName !== 'dashboard-forum') {
        gSettings.set('dashboard.tab', viewName);
        $timeout(function () {
          $rootScope.$broadcast('tabChanged', { name: name, view: viewName });
        });
      }

      if (name === 'BackTester' || name === 'Realtime') {
        $timeout(function () {
          $rootScope.$broadcast('refresh:slider');
        });
      }
    }

    /**
     * This function simulates select tab action.
     *
     * @param {String} name - tab name
     * @param {String} viewName - view name
     */
    function selectTab(name, viewName) {
      if (viewName === 'dashboard-community') {
        // if (btRestrictionService.hasFeature('community')) {
        btSharedData.openDashboardTabByViewName(viewName);
        // } else {
        //   btRestrictionService.showUpgradePopup('community');
        // }
        return;
      }

      if (viewName === 'dashboard-twitter') {
        if (btRestrictionService.hasFeature('twitter-scanner')) {
          btSharedData.openDashboardTabByViewName(viewName);
        } else {
          btRestrictionService.showUpgradePopup('twitter-scanner-enable');
        }
        return;
      }

      console.error(gPrefix, 'unsupported tab was selected!');
    }

    /**
     * This function reacts on tab deselected.
     *
     * @param {String} name - tab name
     */
    function onTabDeselected(name) {
      if (gDebug) console.log(gPrefix, 'onDashboardTabDeselected', name);
    }

    /**
     *
     */
    function switchTvPopup() {
      if ($scope.showVideo) {
        closeTvPopup();
      } else {
        if (btRestrictionService.hasFeature('live-news')) {
          $scope.showVideo = true;
          gSettings.set('live-news.opened', true);
        } else {
          btRestrictionService.showUpgradePopup('live-news');
        }
      }
    }

    /**
     *
     */
    function closeTvPopup() {
      $scope.showVideo = false;
      gSettings.set('live-news.opened', false);
      //$scope.minimized = false;
      //var player = YT.get('tv-widget-player');
      //player.stopVideo();
    }

    function closeLdsTrades() {
      $scope.showLdsTrades = false;
    }

    /**
     *
     */
    function openUpgrade() {
      btRestrictionService.showUpgradePopup('backtester');
    }

    /**
     * Open EMDA link
     */
    function emdaRedirection() {
      window.open('https://app.energymda.com', '_blank');
    }

    /**
     * Open sphere link
     */
    function sphereRedirection() {
      window.open('https://app.sphere.buzz', '_blank');
    }
  }
})();
