/**
 * Created by Sergey Panpurin on 08/11/2022.
 */

(function ldsTradesPopupClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * This directive show LDS trades popup
     *
     * @ngdoc directive
     * @name ldsTradesPopup
     * @memberOf dashboard
     */
    .directive('ldsTradesPopup', directive);

  directive.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function directive($templateCache) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        grade: '<',
      },
      template: $templateCache.get('dashboard/directives/common/lds-trades-popup.html'),
      controller: controller,
      link: function link(scope, element) {
        // https://github.com/desandro/draggabilly
        var $draggable = element.draggabilly({
          handle: '#lds-trades-popup-handle',
          containment: '#bt-main-container',
          grid: [5, 5],
        });

        scope.$on('$destroy', function () {
          $draggable.draggabilly('destroy');
        });
      },
    };
  }

  controller.$inject = ['$q', '$scope', '$timeout', 'btLinkDataService'];

  /**
   *
   * @param {ecapp.ICustomScope} $q
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ILinkDataService} btLinkDataService
   */
  function controller($q, $scope, $timeout, btLinkDataService) {
    const UPDATE_INTERVAL = 1000;
    const MAX_ERROR = 10;

    $scope.trades = [];
    $scope.selected = null;

    $scope.hasError = false;
    $scope.errorMessage = '';
    $scope.isLoading = true;
    $scope.errorCounter = 0;
    $scope.updateCount = 0;

    $scope.streamId = '';
    $scope.streamInterval = null;

    $scope.minimized = false;
    $scope.minimize = minimize;
    $scope.close = close;
    $scope.select = select;

    startTradesStream();

    $scope.$watch('grade.id', function (newValue, oldValue) {
      if (newValue === oldValue) return;

      stopTradesStream();
      startTradesStream();
    });

    $scope.$on('$destroy', onDestroy);

    /**
     *
     */
    function onDestroy() {
      stopTradesStream();
    }

    /**
     *
     */
    function minimize() {
      $scope.minimized = !$scope.minimized;
    }

    /**
     *
     */
    function close() {
      $scope.$parent.closeLdsTrades();
    }

    /**
     *
     */
    function select() {}

    /**
     *
     */
    function startTradesStream() {
      $scope.trades = [];
      $scope.isLoading = true;
      $scope.errorCounter = 0;
      $scope.updateCount = 0;
      $scope.streamId = `stream-${$scope.grade.id}`;

      updateTrades($scope.streamId).then(function () {
        $scope.isLoading = false;
      });
    }

    /**
     *
     */
    function stopTradesStream() {
      $scope.streamId = 'stopped';
      if ($scope.streamInterval) $timeout.cancel($scope.streamInterval);
      $scope.streamInterval = null;
    }

    /**
     * Updates trades
     *
     * @param {string} streamId - stream identifier
     * @return {Promise<any>}
     */
    function updateTrades(streamId) {
      const started = Date.now();

      return getTrades(streamId).then(function () {
        const finished = Date.now();
        const remain = Math.max(UPDATE_INTERVAL - (finished - started), 0);
        if (streamId !== $scope.streamId) return;
        if ($scope.streamInterval) $timeout.cancel($scope.streamInterval);
        $scope.streamInterval = $timeout(function () {
          updateTrades(streamId);
        }, remain);
      });
    }

    /**
     * Returns trades
     *
     * @param {string} streamId - stream identifier
     * @return {Promise<any>}
     */
    function getTrades(streamId) {
      $scope.hasError = false;
      $scope.errorMessage = '';
      const date = moment().tz('America/Chicago');
      $scope.date = date.format('MM/DD/YYYY HH:mm z');

      if ($scope.errorCounter > MAX_ERROR) return $q.reject(new Error('Too many errors'));

      return btLinkDataService
        .getTrades($scope.grade.id, date.format('YYYY-MM-DD'))
        .then(function (trades) {
          if (streamId !== $scope.streamId) return;

          $scope.updateCount++;
          $scope.trades = trades;
          $scope.trades.forEach(function (trade) {
            trade.tradeDate = trade.tradeDate.slice(11);
          });

          // $scope.filteredTrades = filterTrades($scope.trades);
          $scope.hasError = false;
          $scope.errorCounter = 0;
        })
        .catch(function (err) {
          console.error(err);
          
          if (streamId !== $scope.streamId) return;

          $scope.errorMessage = err.message || 'Unable to load trades due to unknown problem';
          $scope.hasError = true;
          $scope.errorCounter++;
        });
    }
  }
})();
