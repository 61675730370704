(function btChartsCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btChartsCtrl', btChartsController);

  btChartsController.$inject = [
    '$scope',
    '$state',
    '$rootScope',
    '$timeout',
    '$analytics',
    '$templateCache',
    '$ionicPopup',
    'btShareScopeService',
    'btSettingsService',
    'btWatchListService',
    'btToastrService',
    'btChartsService',
    'btRestrictionService',
    'btBarometerService',
    'btTradeIdeasFiltersService',
  ];

  /**
   * This is back testing controller.
   * There are form data used to form and realForm data used to getMore request.
   *
   * @ngdoc controller
   * @name BackTesterFormController
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {ext.IAnalyticsService} $analytics
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ionic.IPopupService} $ionicPopup - ionic popup service
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IChartsService} btChartsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IBarometerService} btBarometerService
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   */
  function btChartsController(
    $scope,
    $state,
    $rootScope,
    $timeout,
    $analytics,
    $templateCache,
    $ionicPopup,
    btShareScopeService,
    btSettingsService,
    btWatchListService,
    btToastrService,
    btChartsService,
    btRestrictionService,
    btBarometerService,
    btTradeIdeasFiltersService
  ) {
    console.log('Running btChartsController');

    /**
     * @namespace TradingView
     * @property {Function} widget - ?
     */

    $scope.isBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';

    // Whether charts were initialized
    $scope.initialized = false;

    // Whether charts generation in process
    $scope.processing = false;

    $scope.isLoading = true;
    $scope.instruments = [];
    $scope.representations = [];
    $scope.activeChart = btChartsService.getActiveChart();

    $scope.barometer = btBarometerService.getBarometer();
    $scope.hasCustomization = btRestrictionService.hasFeature('custom-charts');
    $scope.selectInstrument = selectInstrument;
    $scope.addInstrument = addInstrument;
    $scope.saveCharts = saveCharts;
    $scope.restoreCharts = restoreCharts;
    $scope.showPopUp = showPopUp;
    $scope.toggleBarometer = toggleBarometer;
    $scope.openTutorial = openTutorial;

    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    $scope.adDetails = null;
    $scope.hasWidget = false;

    $scope.isOpenAccountEnabled = false;
    $scope.hasOpenAccountBanner = function () {
      return $scope.isOpenAccountEnabled;
    };
    $scope.openAccountLink = '';
    $scope.openAccountLinkText = '';
    $scope.openAccountImage = '';
    $scope.openAccountAlt = '';
    $scope.openAccountText = '';

    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);
    $scope.$on('broker:connected', onBrokerConnect);

    activate();

    /**
     * Update banner settings
     *
     * @return {Promise<null>}
     */
    function updateOpenAccountSettings() {
      if (btSettingsService.isLinkDataService()) {
        return Promise.resolve(null);
      }
      return btShareScopeService
        .getAppSettings('trading')
        .then(function (result) {
          if (!$rootScope.isBTD) return null;

          var config = result.openAccountBar || {};
          $scope.isOpenAccountEnabled = config.enabled;
          $scope.openAccountLink = config.linkHref;
          $scope.openAccountLinkText = config.linkText;
          $scope.openAccountImage = config.imageSrc;
          $scope.openAccountAlt = config.imageAlt;
          $scope.openAccountText = config.text;

          return null;
        })
        .catch(function (reason) {
          console.error(reason);
          return null;
        });
    }

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      $scope.hasCustomization = btRestrictionService.hasFeature('custom-charts');
    }

    /**
     *
     * @param {*} event
     * @param {*} broker
     */
    function onBrokerConnect(event, broker) {
      void event;
      void broker;
      btChartsService.removeCharts();
      generateCharts();
    }

    /**
     *
     */
    function activate() {
      btWatchListService
        .initialize()
        .then(btBarometerService.init)
        .then(updateOpenAccountSettings)
        .then(function () {
          //TODO when watchlist is updated this instruments list is not updated
          $scope.instruments = btWatchListService.getUserWatchlist();
          if (!$scope.instruments.length) {
            $scope.instruments = btWatchListService.getDefaultWatchListInstruments();
          }

          $scope.isLoading = false;

          if (!$scope.initialized) generateCharts();

          // $scope.isMobile = window.isMobile;
          // $scope.isIOS = window.isIOS;
          // $scope.isAndroid = window.isAndroid;

          // RefactorMe: Create special service
          // Sets platform to web if web, ios or android if app, mobileWeb if accessed on mobile Web
          $scope.platform = window.btPlatform;
        });
    }

    /**
     *
     */
    function showPopUp() {
      btChartsService.showPopUp();
    }

    /**
     *
     */
    function onViewLoaded() {
      onViewOpen();
    }

    /**
     *
     */
    function onViewEnter() {
      onViewOpen();
    }

    /**
     *
     */
    function onViewOpen() {
      $timeout(function () {
        $analytics.eventTrack('open', { category: 'dashboard', label: 'charts' });
      }, 1000);
    }

    /**
     *
     */
    function generateCharts() {
      if ($scope.processing) return;

      $scope.initialized = true;
      $scope.processing = true;

      if (window.TradingView === undefined) {
        $timeout(generateCharts, 500, false);
        return;
      }

      btChartsService.displayCharts();
      $scope.processing = false;
    }

    /**
     * This function sets symbol for active chart
     * @param {ecapp.ITradingInstrument} instrument - name of the symbol
     */
    function selectInstrument(instrument) {
      if (btRestrictionService.hasFeature('custom-charts')) {
        btChartsService.updateChart(btChartsService.getChartSymbol(instrument));
      } else {
        btRestrictionService.showUpgradePopup('custom-charts');
      }
    }

    /**
     * @deprecated
     * @see scopes.InstrumentSearch
     * @param {ecapp.ITradingInstrument} instrument - instrument
     * @return {angular.IPromise<void>}
     */
    function addInstrument(instrument) {
      // var toast = btToastrService.info('Saving...', instrument.displayName, {
      //   type: 'market',
      //   hidden: true
      // });

      return btWatchListService.addInstrumentByName(instrument.brokerSymbol).then(function () {
        // btToastrService.remove(toast);
        // btToastrService.info('Has been added to your Watchlist.', instrument.displayName, {type: 'market'});
        btTradeIdeasFiltersService.updateInstrumentsByName(instrument.brokerSymbol, true);
        // return data;
      });
      // .catch(function (reason) {
      // btToastrService.remove(toast);
      // btToastrService.error(reason.message || 'Unknown error!', instrument.displayName, {type: 'market'});
      // });
    }

    /**
     * This function saves selected symbols as default for all charts
     */
    function saveCharts() {
      if (btRestrictionService.hasFeature('custom-charts')) {
        btChartsService.saveCharts();

        var params = {
          timeOut: 3000,
          closeButton: true,
          type: 'settings',
          onTap: openChartPage,
        };

        btToastrService.success('Current charts being displayed are saved for next time.', 'Charts', params);
      } else {
        btRestrictionService.showUpgradePopup('custom-charts');
      }
    }

    /**
     * This function restores default symbols for all charts.
     */
    function restoreCharts() {
      if (btRestrictionService.hasFeature('custom-charts')) {
        btChartsService
          .confirmRestoreCharts()
          .then(function (res) {
            if (res) {
              btChartsService.restoreCharts();
              btToastrService.success('Charts has been restored', 'Charts', { type: 'settings', onTap: openChartPage });
            }
          })
          .catch(function (reason) {
            console.error(reason);
            btToastrService.error('Error: ' + reason.message || 'unknown error', 'Charts', { type: 'settings' });
          });
      } else {
        btRestrictionService.showUpgradePopup('custom-charts');
      }
    }

    /**
     * This function opens tutorial video.
     */
    function openTutorial() {
      if (!$scope.isBigBrainBank) {
        window.open('https://youtu.be/FNJrWe7TCyo', '_system');
      } else {
        window.open('https://youtube.com/BigBrainBank', '_system');
      }
    }

    /**
     * This function opens chart settings.
     */
    function openChartPage() {
      $state.go('ecapp.app.charts');
    }

    /**
     * This function toggle barometer widget.
     */
    function toggleBarometer() {
      btBarometerService.toggle();
    }
  }
})();
