/**
 * Created by Sergey Panpurin on 08/11/2022.
 */

(function ldsTradesTableClosure() {
  'use strict';

  var gDebug = false;

  angular
    .module('dashboard')
    /**
     * This directive show LDS trades table
     *
     * @ngdoc directive
     * @name ldsTradesTable
     * @memberOf dashboard
     */
    .directive('ldsTradesTable', directive);

  directive.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function directive($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('dashboard/directives/common/lds-trades-table.html'),
      controller: controller,
    };
  }

  controller.$inject = ['$q', '$scope', '$timeout', 'btLinkDataService', 'btTradingService', 'btSettingsService', 'btLinkDataServiceApiService', 'btShareScopeService'];

  /**
   *
   * @param {ecapp.ICustomScope} $q
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ILinkDataService} btLinkDataService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.ILinkDataServiceApiService} btLinkDataServiceApiService
   * @param {ecapp.IShareScopeService} btShareScopeService
   */
  function controller($q, $scope, $timeout, btLinkDataService, btTradingService, btSettingsService, btLinkDataServiceApiService, btShareScopeService) {
    const UPDATE_INTERVAL = 5000;
    const MAX_ERROR = 10;

    $scope.trades = [];
    $scope.selected = null;

    $scope.hasError = false;
    $scope.errorMessage = '';
    $scope.isLoading = true;
    $scope.errorCounter = 0;
    $scope.updateCount = 0;
    $scope.grade = null;

    $scope.streamId = '';
    $scope.streamInterval = null;

    $scope.selected = {
      symbol: null,
      lastSymbol: null,
    };
    $scope.showLdsTrades = false;
    $scope.ldsSelectedGrade = null;
    $scope.openTrades = openTrades;

    $scope.$on('lds:trades:table:open', onLdsTradesTableOpen);
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * This function activate controller.
     */
    function activate() {
      if (gDebug) console.log('btBarometerWidgetsController: view', $scope.view);
      getSymbols();
    }

    /**
     *
     */
    function onDestroy() {
      onLdsTradesTableClose();
    }

    /**
     * This function get transaction symbols
     */
    function getSymbols() {
      $scope.symbols = [];
      if (btSettingsService.isLinkDataService()) {
        btLinkDataService.getDailyTable().then(function (table) {
          let symbols = table.data
            .map((d) => d.symbols)
            .flat()
            .map((symbol) => {
              const grade = btLinkDataService.getGradeById(symbol.id);
              const instrument = btTradingService.getInstrumentByBrokerSymbol(grade.name);
              symbol.instrument = instrument;
              symbol.hasPrice = instrument.hasPriceAccess();
              symbol.hasTrades = instrument.hasTradesAccess();
              symbol.displayName = instrument.displayName;
              return symbol;
            });
          $scope.symbols = symbols;
          $scope.isLoading = false;
        });
      }
    }

    /**
     * Open LDS trades popup
     * @param {any} event
     * @param {number} grade
     */
    function onLdsTradesTableOpen(event, grade) {
      if (!$scope.grade || $scope.grade.id !== grade.id) {
        const symbolId = grade.id.toString();
        $scope.grade = grade;
        $scope.selected.symbol = symbolId;
        $scope.selected.lastSymbol = symbolId;
        stopTradesStream();
        startTradesStream();
      }
    }

    /**
     * Close LDS trades popup
     */
    function onLdsTradesTableClose() {
      $scope.grade = null;
      stopTradesStream();
    }

    /**
     * Open trades
     */
    function openTrades() {
      const symbolId = $scope.selected.symbol;
      if (symbolId) {
        const symbol = $scope.symbols.find((s) => s.id === Number(symbolId));
        if (symbol) {
          if (!symbol.hasPrice) {
            btLinkDataServiceApiService.requestLdsAccess(symbol, 'price');
            this.selected.symbol = $scope.selected.lastSymbol;
          } else if (!symbol.hasTrades) {
            btLinkDataServiceApiService.requestLdsAccess(symbol, 'trades');
            this.selected.symbol = $scope.selected.lastSymbol;
          } else {
            onLdsTradesTableOpen(null, symbol);
            $scope.selected.lastSymbol = symbolId;
          }
        } else {
          onLdsTradesTableClose();
          $scope.selected.lastSymbol = symbolId;
        }
      } else {
        onLdsTradesTableClose();
        $scope.selected.lastSymbol = symbolId;
      }
    }

    /**
     *
     */
    function startTradesStream() {
      $scope.trades = [];
      $scope.isLoading = true;
      $scope.errorCounter = 0;
      $scope.updateCount = 0;
      $scope.streamId = `stream-${$scope.grade.id}`;

      updateTrades($scope.streamId).then(function () {
        $scope.isLoading = false;
      });
    }

    /**
     *
     */
    function stopTradesStream() {
      $scope.streamId = 'stopped';
      if ($scope.streamInterval) $timeout.cancel($scope.streamInterval);
      $scope.streamInterval = null;
    }

    /**
     * Updates trades
     *
     * @param {string} streamId - stream identifier
     * @return {Promise<any>}
     */
    function updateTrades(streamId) {
      const started = Date.now();

      return getTrades(streamId).then(function () {
        const finished = Date.now();
        const remain = Math.max(UPDATE_INTERVAL - (finished - started), 0);
        if (streamId !== $scope.streamId) return;
        if ($scope.streamInterval) $timeout.cancel($scope.streamInterval);
        $scope.streamInterval = $timeout(function () {
          updateTrades(streamId);
        }, remain);
      });
    }

    /**
     * Returns trades
     *
     * @param {string} streamId - stream identifier
     * @return {Promise<any>}
     */
    function getTrades(streamId) {
      $scope.hasError = false;
      $scope.errorMessage = '';
      const timezone = btShareScopeService.accountInfo.timeZone || 'America/Chicago';
      const date = moment().tz(timezone);
      $scope.date = date.format('MM/DD/YYYY hh:mm A z');

      if ($scope.errorCounter > MAX_ERROR) return $q.reject(new Error('Too many errors'));

      return btLinkDataService
        .getTrades($scope.grade.id, date.format('YYYY-MM-DD'))
        .then(function (trades) {
          if (streamId !== $scope.streamId) return;

          $scope.updateCount++;
          $scope.trades = trades.sort(
            (a, b) => moment(b.executionTimeUtc) - moment(a.executionTimeUtc)
          );
          $scope.trades.forEach(function (trade) {
            if(btSettingsService.isLinkDataService()){
              const utcCutoff = moment.utc(trade.executionTimeUtc, 'YYYY-MM-DD HH:mm:ss');
              trade.executionTimeUtc = utcCutoff.clone().tz(timezone).format('hh:mm:ss A');
            } else {
              trade.tradeDate = trade.tradeDate.slice(11);
            }
          });

          // $scope.filteredTrades = filterTrades($scope.trades);
          $scope.hasError = false;
          $scope.errorCounter = 0;
        })
        .catch(function (err) {
          console.error(err);

          if (streamId !== $scope.streamId) return;

          $scope.errorMessage = err.message || 'Unable to load trades due to unknown problem';
          $scope.hasError = true;
          $scope.errorCounter++;
        });
    }
  }
})();
